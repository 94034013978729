import { Fragment, useEffect } from 'react';
import Content from './routes/main-routes';
import './styles/main.scss';
import './styles/tailwind.scss';
import './assets/scss/style.scss';
import { useTranslation } from 'react-i18next';
import { PreferencesService } from './utilities/services/preferences-service/preferences-service';
import { getURLParams } from './utilities/url/getUrlParam';

function App() {

  const { t, i18n } = useTranslation();
  const auth = getURLParams('auth');

  useEffect(() => {
    const fetchLanguagePreference = async () => {
      // GET USER PREFERENCES
      try {
        let preferences = await PreferencesService.getUserPreferences(auth);
        const preferredLanguage = preferences?.preferences.filter(item => item.key.toLowerCase() === 'language')[0]?.value;
        if (preferredLanguage) {
          i18n.changeLanguage(preferredLanguage.split('_')[0] || 'en');
        }
      } catch (error) {
        console.log('failed to get user preferences ', error);
      }
    };

    if (auth) fetchLanguagePreference();
  }, [i18n]);

  return (
    <Fragment>
      <Content />
    </Fragment>
  );
}

export default App;
