import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enLang from './locales/en.json';
import frLang from './locales/fr.json';
import hrLang from './locales/hr.json';
import { getURLParams } from "../url/getUrlParam";

const regionParam = getURLParams('region');
const language = getURLParams('lang');
const region = language ? language : regionParam ? regionParam : 'GB'.toLowerCase();
const selectedLanguage = region.toLowerCase() === 'gb' ? 'en' : region.toLowerCase();

const resources = {
    en: {
        translation: enLang,
    },
    fr: {
        translation: frLang,
    },
    hr: {
        translation: hrLang,
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en', // fallback language set if selected language code is not correct
        lng: selectedLanguage ? selectedLanguage : 'en',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;