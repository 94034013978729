export const getURLParams = (param: string | number): string => {
  const searchParam = new URLSearchParams(window.location.search);
  const urlParam = searchParam.get(param.toString()) || '';
  return urlParam;
};

export const getURLParamMap = (): any => {
  const searchParams = new URLSearchParams(window.location.search);

  let paramMap = {};
  searchParams.forEach((value, key) => {
    paramMap[key] = value;
  });
  return paramMap;
};