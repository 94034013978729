import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_USER_SERVICE_V2_URL}`,
});

const responseBody = (response: AxiosResponse) => response.data;


const requests = {
    get: (url: string) => instance.get(url).then(responseBody),
    post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
};

export const PreferencesService = {
    getUserPreferences: (authToken): Promise<any> => requests.get(`/user/preferences?auth=${authToken}`),
    updateUserPreferences: (authToken, body): Promise<{ result: any }> => requests.post(`/user/preferences?auth=${authToken}`, body),
};